import * as React from 'react';

import Countdown from './Countdown';
import { CountdownEntry } from './../models/CountdownEntry.model';

import './../assets/scss/CountdownList.scss';

interface Props {

}

export default class CountdownList extends React.Component<Props> {
    // private entryList: CountdownEntry[] = [];

    public state = {
        entryList: CountdownEntry.getAll()
    };

    public createEntry = () => {
        const entryList = [...this.state.entryList, CountdownEntry.create().save()];
        this.setState({ entryList });
    }

    public updateList = () => {
        this.setState({
            entryList: CountdownEntry.getAll()
        });
    }

    public render() {
        return (
            <div className="countdown-list-wrapper">
                <h1>Countdowns</h1>

                <ul className="list">
                    {this.state.entryList.map((entry, index) => (
                        <li key={index}>
                            <Countdown countdown={entry} updateCallback={this.updateList}/>
                        </li>
                    ))}
                </ul>

                <button onClick={this.createEntry}>Create entry</button>
            </div>
        );
    }
}
