import * as moment from 'moment';

export class CountdownEntry {
    public static parse(entry) {
        return new CountdownEntry(entry);
    }

    public static getAll(): CountdownEntry[] {
        return JSON.parse(window.localStorage.getItem('countdownEntries') || '[]').map( entry => CountdownEntry.parse(entry));
    }

    public static save(entry: CountdownEntry) {
        const countdowns = CountdownEntry.getAll();
        let isFound = false;

        countdowns.forEach(({id}, index) => {
            if (id === entry.id) {
                countdowns[index] = entry;
                isFound = true;
            }
        });

        if (!isFound) {
            countdowns.push(entry);
        }

        window.localStorage.setItem('countdownEntries', JSON.stringify(countdowns.map(entry => entry.toJson())));
    }

    public static remove(entry: CountdownEntry): void {
        const countdowns = CountdownEntry.getAll();

        window.localStorage.setItem('countdownEntries', JSON.stringify(countdowns.filter(({ id }) => entry.id !== id).map(entry => entry.toJson())));
    }

    public static create(): CountdownEntry {
        return new CountdownEntry({
            id: Date.now(),
            title: 'This is a title',
            endDate: new Date().getTime()
        });
    }

    public id: string;
    public title: string;
    public endDate: number;

    public formattedDate: string = '';

    constructor({id, title, endDate}) {
        this.id = id;
        this.title = title;
        this.endDate = endDate;
        this.setFormattedDate();
    }

    public save() {
        this.endDate = moment(this.formattedDate).valueOf();
        CountdownEntry.save(this);
        return this;
    }

    public remove() {
        CountdownEntry.remove(this);
    }

    public edit() {
        this.setFormattedDate();
    }

    public get dateHumanReadable(): string {
        return new Date(this.endDate).toString();
    }

    public get daysToDate(): number {
        const today = moment(new Date());
        const end = moment(new Date(this.endDate));
        return end.diff(today, 'days');
    }

    public get date(): Date {
        return new Date(this.endDate);
    }

    public toJson() {
        return {
            id: this.id,
            title: this.title,
            endDate: this.endDate
        };
    }

    private setFormattedDate(): void {
        this.formattedDate = moment(this.endDate).format().split('T')[0];
    }
}
