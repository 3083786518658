import * as React from "react";

import CountdownList from './CountdownList';

import "./../assets/scss/App.scss";

export interface AppProps {

}

export default class App extends React.Component<AppProps, undefined> {
    render() {
        return (
            <div className="root">
                <CountdownList />
            </div>
        );
    }
}
