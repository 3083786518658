import * as React from 'react';

import { CountdownEntry } from './../models/CountdownEntry.model';

import './../assets/scss/Countdown.scss';

interface Props {
    countdown: CountdownEntry;
    updateCallback: () => void;
}

export default class CountdownE extends React.Component<Props> {
    public state = {
        isEdit: false,
        countdown: this.props.countdown
    };

    public save = () => {
        const { countdown } = this.state;
        countdown.save();
        this.setState({ isEdit: false, countdown });
    }

    public edit = () => {
        const { countdown } = this.state;
        countdown.edit();
        this.setState({ isEdit: true, countdown });
    }

    public remove = () => {
        const { countdown } = this.state;
        countdown.remove();
        this.props.updateCallback();
    }

    public changeTitle = (event) => {
        const { countdown } = this.state;
        countdown.title = event.target.value;
        this.setState({ countdown });
    }

    public changeEndDate = (event) => {
        const { countdown } = this.state;
        countdown.formattedDate = event.target.value;
        this.setState({ countdown });
    }

    public render() {
        if (this.state.isEdit) {
            return (
                <div className="countdown-wrapper edit">
                    <input type="text" className="change-end-date" onChange={this.changeEndDate} value={this.props.countdown.formattedDate} />
                    <input type="text" className="change-title" onChange={this.changeTitle} value={this.props.countdown.title} />
                    <button onClick={this.save} className="save">Save</button>
                    <button onClick={this.remove} className="remove">Remove</button>
                </div>
            );
        } else {
            return (
                <div className="countdown-wrapper">
                    <h1 className="days-to-date">{ this.props.countdown.daysToDate }</h1>
                    <h2 className="countdown-title">{ this.props.countdown.title }</h2>
                    <p className="countdown-note">Ends: { this.state.countdown.formattedDate }</p>
                    <button onClick={this.edit} className="edit">Edit</button>
                </div>
            );
        }
    }
}
